import { remoteConfig } from '../firebase';

export default {
	props: {
		feature: String,
	},
	methods: {
		featureEnabled(feature) {
			return remoteConfig.getValue(feature).asBoolean();
		},
	},
};
