import { mapState } from 'vuex';

export default {
	mounted() {
		if (!this.subscription) {
			this.$store.dispatch('getSubscriptions', this.user);
		}
	},
	computed: {
		...mapState(['user', 'userProfile', 'agreements', 'subscriptions']),
		hasActiveSubscription() {
			let isActive = (this.userProfile.subscriptionStatus === true || this.userProfile.subscriptionStatus === 'active') ? true : false
			return isActive;
		},
		activeSubscription() {
			return this.subscriptions
				? this.subscriptions.find(
						({ role, status }) =>
							role !== 'assistant_plan' && status === 'active'
				  )
				: null;
		},
		hasAssistantSubscription() {
			return !!this.assistantSubscription;
		},
		assistantSubscription() {
			const assistantSubscription = this.subscriptions
				? this.subscriptions.find(
						({ role, status }) =>
							role === 'assistant_plan' && status === 'active'
				  )
				: null;
			return assistantSubscription;
		},
		// determine how many available assistant packages they have
		hasAvailableAssistantSeats() {
			return this.hasAssistantSubscription
				? this.userProfile.assistants
					? this.userProfile.assistants &&
					  this.userProfile.assistants.length <
							this.assistantSubscription.quantity
					: true
				: false;
		},
		seatsRemaining() {
			return this.hasAssistantSubscription
				? this.userProfile.assistants
					? this.userProfile.assistants &&
					  this.userProfile.assistants.length <
							this.assistantSubscription.quantity
					: this.assistantSubscription.quantity
				: 0;
		},
	},
};
