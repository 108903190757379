import { BToast } from 'bootstrap-vue';

export default {
	components: {
		BToast
	},
	methods: {
		successMessage(title = '', message = '',options, toaster) {
			return this.makeToast('success',title, message,options,toaster)
		},
        errorMessage(title = '', message = '',options,toaster) {
			return this.makeToast('danger',title, message,options,toaster)
		},
        warningMessage(title = '', message = '',options,toaster) {
			return this.makeToast('warning',title, message,options,toaster)
		},
        infoMessage(title = '', message = '',options,toaster) {
			return this.makeToast('info',title, message,options,toaster)
		},
        makeToast(variant = null,title, message,options,toaster) {
            return this.$bvToast.toast(message, {
              title: title,
              variant: variant,
			  toaster: toaster,
              solid: options?.solid ? options.solid : true
            })
        }
	}
}