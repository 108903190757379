<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { storage } from '../firebase';

import {
	BIconZoomIn,
	BIconZoomOut,
	BIconArrowLeft,
	BIconArrowRight,
	BIconArrowUp,
	BIconArrowDown,
	BIconArrowClockwise,
	BIconArrowCounterclockwise,
	BIconArrowRepeat,
} from 'bootstrap-vue';

export default {
	components: {
		VueCropper,
		BIconZoomIn,
		BIconZoomOut,
		BIconArrowLeft,
		BIconArrowRight,
		BIconArrowUp,
		BIconArrowDown,
		BIconArrowClockwise,
		BIconArrowCounterclockwise,
		BIconArrowRepeat,
	},
	props: ['currentUser'],
	data() {
		return {
			imgSrc: '',
			data: null,
			aspectRatio: 16 / 9,
			saving: false
		};
	},
	methods: {
		move(offsetX, offsetY) {
			this.$refs.cropper.move(offsetX, offsetY);
		},
		reset() {
			this.$refs.cropper.reset();
		},
		rotate(deg) {
			this.$refs.cropper.rotate(deg);
		},
		zoom(percent) {
			this.$refs.cropper.relativeZoom(percent);
			this.$refs.cropper;
		},
		setAspectRatio(ratio) {
			this.$refs.cropper.setAspectRatio(ratio);
		},
		saveImage() {
			this.saving = true
			const cropper = this.$refs.cropper;
			const croppedCanvas = cropper.getCroppedCanvas();
			const resizedCanvas = document.createElement('canvas');
			const resizedContext = resizedCanvas.getContext('2d');

			const maxWidth = 800;
			const maxHeight = 800;
			let width = croppedCanvas.width;
			let height = croppedCanvas.height;

			if (width > height) {
				if (width > maxWidth) {
				height *= maxWidth / width;
				width = maxWidth;
				}
			} else {
				if (height > maxHeight) {
				width *= maxHeight / height;
				height = maxHeight;
				}
			}
			resizedCanvas.width = width;
			resizedCanvas.height = height;
			resizedContext.drawImage(croppedCanvas, 0, 0, width, height);

			resizedCanvas.toBlob((blob) => {
				const userId = this.currentUser.uid;
				const fileName = `${userId}-logo.png`;
				const file = new File([blob], fileName, { type: 'image/png' });
				const storageRef = storage.ref();
				const userDocsRef = storageRef.child(`userDocs/${fileName}`);
				userDocsRef
					.put(file)
					.then(() => {
						userDocsRef
							.getDownloadURL()
							.then((url) => {
								this.saving = false
								this.$emit('imageSaved', url);
								this.$bvModal.hide('logo-editor');
							})
							.catch((error) => {
								console.error(
									'Error getting download URL:',
									error
								);
							});
					})
					.catch((error) => {
						console.error('Error uploading image:', error);
					});
			}, 'image/png');
		},
	},
};
</script>

<style>
.actions {
	margin-top: 1rem;
}

.preview-area {
	width: 307px ;
	min-height: 279px;
}

.preview-area p {
	font-size: 1.25rem;
	margin: 0;
	margin-bottom: 1rem;
}

.preview-box {
	width: 100%;
	height: calc(372px * (9 / 16));
	overflow: hidden;
}

#logo-editor{
	max-height: 85vh;
}

.footer{
	height: 4rem;
	position: sticky;
    bottom: 0; 
	background-color: white;
	margin-left: -16px; 
    margin-right: -16px;
}

#logo-editor .modal-body{
	padding-bottom: 0;
}

.save-btn{
	margin-right: 1rem;
}

@media (max-width: 425px) {
	.btn {
		padding: 0.25rem 0.5rem !important;
		font-size: 0.8rem !important;
  	}

	#logo-editor{
		max-height: 75vh;
	}

}
@media (max-width: 991px) {
	.preview-area p{
		margin-top: 2rem;
  	}
}
</style>

<template>
	<div>
		<div>
			<div class="d-flex justify-content-around flex-column">
				<div class="d-flex justify-content-between flex-wrap cropper-section">
					<section class="col-5">
						<div class="img-cropper">
							<vue-cropper ref="cropper" :aspect-ratio="16 / 9" :src="imgSrc" preview=".preview-box" />
						</div>
					</section>
					<section class="preview-area">
						<p>Preview</p>
						<div class="preview-box" />
					</section>
				</div>
				<section class="button-section mt-5 d-flex align-items-center justify-content-between flex-wrap">
					<b-button-group class="mt-2">
						<b-button @click="zoom(0.2)" variant="primary" data-testid='zoom-in'>
							<b-icon-zoom-in></b-icon-zoom-in>
						</b-button>
						<b-button @click="zoom(-0.2)" variant="primary" data-testid='zoom-out'>
							<b-icon-zoom-out></b-icon-zoom-out>
						</b-button>
					</b-button-group>
					<b-button-group class="mt-2">
						<b-button @click="rotate(90)" variant="primary" data-testid='rotate-left'>
							<b-icon-arrow-clockwise></b-icon-arrow-clockwise>
						</b-button>
						<b-button @click="rotate(-90)" variant="primary" data-testid='rotate-right'>
							<b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
						</b-button>
					</b-button-group>
					<b-button-group class="mt-2">
						<b-button @click="move(-10, 0)" variant="primary" data-testid='move-left'>
							<b-icon-arrow-left></b-icon-arrow-left>
						</b-button>
						<b-button @click="move(10, 0)" variant="primary" data-testid='move-right'>
							<b-icon-arrow-right></b-icon-arrow-right>
						</b-button>
						<b-button @click="move(0, -10)" variant="primary" data-testid='move-top'>
							<b-icon-arrow-up></b-icon-arrow-up>
						</b-button>
						<b-button @click="move(0, 10)" variant="primary" data-testid='move-bottom'>
							<b-icon-arrow-down></b-icon-arrow-down>
						</b-button>
					</b-button-group>
					<b-button @click.prevent="reset" variant="primary" data-testid='reset-image' class="mt-2"
						><b-icon-arrow-repeat></b-icon-arrow-repeat>
					</b-button>
					<b-button-group class="mt-2">
						<b-button @click.prevent="setAspectRatio(16 / 9)" variant="primary"
							data-testid='set-aspect-ratio-16/9'>
							16 : 9
						</b-button>
						<b-button @click.prevent="setAspectRatio(4 / 3)" variant="primary"
							data-testid='set-aspect-ratio-4/3'>
							4 : 3
						</b-button>
						<b-button @click.prevent="setAspectRatio(1 / 1)" variant="primary"
							data-testid='set-aspect-ratio-1/1'>
							1 : 1
						</b-button>
						<b-button @click.prevent="setAspectRatio(2 / 3)" variant="primary"
							data-testid='set-aspect-ratio-2/3'>
							2 : 3
						</b-button>
						<b-button @click.prevent="setAspectRatio(null)" variant="primary"
							data-testid='set-aspect-ratio-free'>
							free
						</b-button>
					</b-button-group>
				</section>
			</div>
			<div class="mt-5 footer">
				<hr>
				<b-button @click="saveImage" variant="primary" :disabled="saving" class="float-right save-btn" data-testid='save-image'>
					<template v-if="saving">
						<b-spinner small type="grow"></b-spinner>
						Saving...
					</template>
					<template v-else>
						Save
					</template>
				</b-button>
			</div>
			</div>
			
</div></template>
