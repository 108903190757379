import { format, parseISO } from 'date-fns';

export default {
	methods: {
		dateFormatter(date) {
			try {
				return format(parseISO(date), 'PPPp');
			} catch {
				return 'Invalid Date';
			}
		},
		/**
		 * To check whether a given ISO date falls between a date range
		 * @param {number} startDate in seconds
		 * @param {number} endDate in seconds
		 * @param {string} selectedDateInISO in ISO string format
		 */
		dateLiesInsideDateRange(startDate,endDate,selectedDateInISO){
			try {
				if((new Date(startDate * 1000) <= new Date(selectedDateInISO)) && (new Date(endDate * 1000) >= new Date(selectedDateInISO))){
					return true
				} else {
					return false;
				}
			} catch(err) {
				console.error('Error in dateLiesInsideDateRange',err)
				return false;
			}

		}
	},
};
